.loader-main-box {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.8);
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader span {
  display: inline-block;
  width: 5px;
  height: 30px;
  margin: 0 3px;
  background: #ec264e;
  border-radius: 4px;
}

.loader span:nth-child(1) {
  animation: grow 1s ease-in-out infinite;
}

.loader span:nth-child(2) {
  animation: grow 1s ease-in-out 0.15s infinite;
}

.loader span:nth-child(3) {
  animation: grow 1s ease-in-out 0.3s infinite;
}

.loader span:nth-child(4) {
  animation: grow 1s ease-in-out 0.45s infinite;
}

@keyframes grow {
  0%,
  100% {
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1);
  }

  50% {
    -webkit-transform: scaleY(1.8);
    -ms-transform: scaleY(1.8);
    -o-transform: scaleY(1.8);
    transform: scaleY(1.8);
  }
}
