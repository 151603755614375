@font-face {
  font-family: "Akzidenz-Grotesk BQ";
  src: url("../fonts/AkzidenzGroteskBQ-Super.eot");
  src: local("Akzidenz-Grotesk BQ Super"), local("AkzidenzGroteskBQ-Super"),
    url("../fonts/AkzidenzGroteskBQ-Super.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

#launcher-frame {
  left: 0;
}

.launcher-button {
  position: absolute !important;
  left: 25px !important;
}
